import { ReactNode, useCallback } from "react";
import { useModalContext } from "../components/shared/ModalProvider";
import StandardModal from "../components/shared/ModalProvider/StandardModal";

interface UseStandardModalArgs {
  header: ReactNode;
  content: ReactNode;
  footer?: ReactNode;
  modalSize?: "xl" | "lg" | "sm";
}

const useStandardModal = () => {
  const { setModalContent } = useModalContext();
  return useCallback(
    ({ header, content, footer, modalSize }: UseStandardModalArgs) => {
      const modalContent = (
        <StandardModal
          header={header}
          footer={footer}
        >
          {content}
        </StandardModal>
      );
      setModalContent({
        modalSize,
        modalContent: modalContent,
      });
    },
    [setModalContent],
  );
};

export default useStandardModal;
