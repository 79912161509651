import React, { FC, useCallback } from "react";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useJurisdictionContext } from "@taxscribe/ui";

import AppNavbar from "../../shared/AppNavbar";
import SectionContent from "../../shared/SectionContent";
import useStandardModal from "../../../hooks/useStandardModal";
import formPageMap, { standardizeKey } from "../../shared/FormPageMap";
import styled from "styled-components";
import colors from "../../../constants/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import FormLocationModal from "../../shared/FormLocationModal";
import AppNavbarBrand from "../../shared/AppNavbarBrand";
import useFormClientAndLocation from "../../../hooks/useFormClientAndLocation";
import printLocationOnOneLine from "../../../lib/printLocationOnOneLine";

export interface FormPageProps {
  readOnly?: boolean;
}

export interface FormSwitchProps {
  state: string;
  formType: string;
  canSign?: boolean;
}

export const FormSwitch: FC<FormSwitchProps> = ({ state, formType }) => {
  const stateForms = formPageMap[standardizeKey(state)];
  const FormPage = stateForms?.[standardizeKey(formType)];
  if (!FormPage) {
    console.error("Unable to determine form page for:", state, formType);
    return <div>An error occurred</div>;
  }
  return <FormPage />;
};

const StyledButton = styled(Button)`
  & {
    border: 0px solid;
    color: black;
    :hover {
      background-color: ${colors.tsBlueGray};
    }
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const AddressWrapper = styled.span`
  color: ${colors.tsGrayDark};
  font-size: 1rem;
`;

const PageTitle: FC<{ name: string }> = ({ name }) => {
  const { formUuid } = useParams();
  const { data } = useFormClientAndLocation({
    uuid: formUuid,
  });

  const location = data?.form?.clientLocation;
  const client = location?.client;
  if (client) {
    return (
      <TitleWrapper>
        <AppNavbarBrand>{client.name}</AppNavbarBrand>
        <AddressWrapper>
          {printLocationOnOneLine(location, client.state)}
        </AddressWrapper>
      </TitleWrapper>
    );
  }

  return <AppNavbarBrand>{name}</AppNavbarBrand>;
};

const FormSwitchWrapper: FC = () => {
  const launchModal = useStandardModal();
  const { formUuid } = useParams();
  const { state = "", formType = "" } = useParams();
  const { name, stateAbbr, jurisdictionId } = useJurisdictionContext();

  const handleClick = useCallback(async () => {
    if (!formUuid) return;

    launchModal({
      header: "Set Location",
      content: (
        <FormLocationModal
          stateAbbr={stateAbbr}
          formUuid={formUuid}
          jurisdictionId={jurisdictionId}
        />
      ),
    });
  }, [stateAbbr, formUuid, launchModal, jurisdictionId]);

  return (
    <>
      <AppNavbar pageTitle={<PageTitle name={name} />}>
        <StyledButton
          onClick={handleClick}
          variant="outline-light"
        >
          <FontAwesomeIcon icon={faLocationDot} />
        </StyledButton>
      </AppNavbar>
      <SectionContent>
        <FormSwitch
          state={state}
          formType={formType}
        />
      </SectionContent>
    </>
  );
};

export default FormSwitchWrapper;
