import { gql, LazyQueryHookOptions, useLazyQuery } from "@apollo/client";
import { useUserDoc } from "@reasongcp/react-fire-sub";
import { ClientLocation } from "@taxscribe/record-types";
import { useEffect } from "react";

export interface GetOrganizationClientJurisdictionLocationsResults {
  organization: {
    client: {
      id: number;
      locations: ClientLocation[];
    };
  };
}

export const GET_ORGANIZATION_CLIENT_JURISDICTION_LOCATIONS = gql`
  query GetOrganizationClientJurisdictionLocations(
    $clientId: Int!
    $uuid: String!
    $jurisdictionId: Int!
  ) {
    organization(uuid: $uuid) {
      client(id: $clientId) {
        id
        locations(jurisdictionId: $jurisdictionId) {
          id
          address
          addressTwo
          city
          zipCode
          clientId
          jurisdictionId
          createdAt
          updatedAt
        }
      }
      uuid
    }
  }
`;

const useOrganizationClientJurisdictionLocations = (
  clientId: string | number | undefined,
  jurisdictionId: string | number | undefined,
  opts?: LazyQueryHookOptions,
) => {
  const userDoc = useUserDoc();
  const currentOrganization = userDoc?.currentOrganization as
    | string
    | undefined;
  const [fetchClient, results] =
    useLazyQuery<GetOrganizationClientJurisdictionLocationsResults>(
      GET_ORGANIZATION_CLIENT_JURISDICTION_LOCATIONS,
      opts,
    );

  useEffect(() => {
    if (!clientId || !currentOrganization || !jurisdictionId) return;

    const clientIdNumber = Number(clientId);
    if (isNaN(clientIdNumber)) return;

    const jurisdictionIdNumber = Number(jurisdictionId);
    if (isNaN(jurisdictionIdNumber)) return;

    fetchClient({
      variables: {
        clientId: clientIdNumber,
        uuid: currentOrganization,
        jurisdictionId: jurisdictionIdNumber,
      },
    });
  }, [fetchClient, clientId, currentOrganization, jurisdictionId]);

  return results;
};

export default useOrganizationClientJurisdictionLocations;
