import React, { FC, useCallback, useEffect } from "react";
import styled from "styled-components";
import { Formik, useField, Form, useFormikContext } from "formik";
import { Col, Container, Row } from "react-bootstrap";
import { BooleanGuard, SpinnerButton, useToasts } from "@taxscribe/ui";

import { useModalContext } from "../ModalProvider";
import FormikClientSelect from "../FormikClientSelect";
import ModalLocationSelect from "./ModalLocationSelect";
import useAttachFormToLocation from "./useAttachFormToLocation";
// eslint-disable-next-line max-len
import useOrganizationClientJurisdictionLocations from "../../../hooks/useOrganizationClientJurisdictionLocations";

export interface FormLocationModalProps {
  formUuid: string;
  stateAbbr: string;
  jurisdictionId: number;
}

const initialValues = {
  clientId: "",
  locationId: "",
};

type InitialValues = typeof initialValues;

const PaddedRow = styled(Row)`
  padding-top: 1rem;
`;

const FormLocationForm: FC<FormLocationModalProps> = ({
  stateAbbr,
  jurisdictionId,
}) => {
  const { isSubmitting, setFieldValue } = useFormikContext();
  const [clientIdField] = useField<InitialValues["clientId"]>("clientId");
  const clientId = clientIdField.value;
  const [locationIdField] = useField<InitialValues["locationId"]>("locationId");
  const locationId = locationIdField.value;

  const { data, loading, called } = useOrganizationClientJurisdictionLocations(
    clientId,
    jurisdictionId,
    { fetchPolicy: "cache-and-network" },
  );

  const showLocations = !!clientId && !loading && called;
  const clientLocations = data?.organization?.client?.locations;
  const showSubmit =
    showLocations && Boolean(clientLocations?.length) && Boolean(locationId);

  useEffect(() => {
    setFieldValue("locationId", "");
  }, [clientId, setFieldValue]);

  return (
    <Form>
      <Container>
        <PaddedRow>
          <Col>
            <FormikClientSelect
              label="Select a client"
              fieldName="clientId"
            />
          </Col>
        </PaddedRow>
        <BooleanGuard condition={showLocations}>
          <PaddedRow>
            <Col>
              <ModalLocationSelect
                stateAbbr={stateAbbr}
                jurisdictionId={jurisdictionId}
                clientLocations={clientLocations}
              />
            </Col>
          </PaddedRow>
        </BooleanGuard>
        <BooleanGuard condition={showSubmit}>
          <PaddedRow>
            <Col>
              <SpinnerButton
                spin={isSubmitting}
                type="submit"
              >
                Set Location
              </SpinnerButton>
            </Col>
          </PaddedRow>
        </BooleanGuard>
      </Container>
    </Form>
  );
};

const FormLocationModal: FC<FormLocationModalProps> = (props) => {
  const { formUuid } = props;
  const { toastError, toastSuccess } = useToasts();
  const [attachForm] = useAttachFormToLocation();
  const { closeModal } = useModalContext();

  const handleSubmit = useCallback(
    async (values: InitialValues) => {
      const results = await attachForm({
        variables: {
          formUuid: formUuid,
          locationId: Number(values.locationId),
        },
      }).catch((err) => {
        console.error("Error attaching form to location");
        console.error(err);
        return null;
      });

      if (!results) {
        toastError("Error Setting Location");
        return;
      }

      toastSuccess("Location Set");
      closeModal();
    },
    [attachForm, formUuid, toastError, toastSuccess, closeModal],
  );

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      <FormLocationForm {...props} />
    </Formik>
  );
};

export default FormLocationModal;
