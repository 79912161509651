import { gql, useLazyQuery } from "@apollo/client";
import { ClientLocation } from "@taxscribe/record-types";
import { useEffect } from "react";

export const GET_FORM_CLIENT_AND_LOCATION = gql`
  query Form($uuid: String!) {
  form(uuid: $uuid) {
    id
    ddbKey
    clientLocation {
      id
      address
      addressTwo
      city
      zipCode
      clientId
      stateAbbr
      jurisdictionId
      createdAt
      updatedAt
      client {
        id
        organizationId
        email
        name
        contactName
        phoneNumber
        address
        addressTwo
        city
        state
        zipCode
        website
        createdAt
        updatedAt
      }
    }
  }
}
`;

export interface GetFormClientAndLocationResults {
  form: {
    id: number;
    ddbKey: string;
    clientLocation: ClientLocation & {
      client: {
        id: number;
        organizationId: string;
        email: string;
        name: string;
        contactName: string;
        phoneNumber: string;
        address: string;
        addressTwo: string;
        city: string;
        state: string;
        zipCode: string;
        website: string;
        createdAt: string;
        updatedAt: string;
      };
    };
  };
}

export interface ClientLocationArgs {
  uuid: string;
}

export const useLazyFormClientAndLocation = () => {
  return useLazyQuery<
    GetFormClientAndLocationResults,
    ClientLocationArgs
  >(
    GET_FORM_CLIENT_AND_LOCATION,
  );
};

const useFormClientAndLocation = (args: Partial<ClientLocationArgs>) => {
  const { uuid } = args;
  const [query, results] = useLazyFormClientAndLocation();

  useEffect(() => {
    if (uuid) {
      query({ variables: { uuid } });
    }
  }, [query, uuid]);

  return results;
};

export default useFormClientAndLocation;
