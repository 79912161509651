import roleMappings, { UserRole } from "./roleMappings";

// NOTE: An empty array implies admin only access.

// Users
export const manageUsers: UserRole[] = [];

// Forms
export const editForms: UserRole[] = [
  roleMappings.manager,
  roleMappings.preparer,
];
export const viewForms: UserRole[] = [
  roleMappings.user,
  roleMappings.billing,
  roleMappings.manager,
  roleMappings.preparer,
  roleMappings.signer,
];
export const submitForms: UserRole[] = [
  roleMappings.manager,
  roleMappings.signer,
];
export const signForms: UserRole[] = [
  roleMappings.signer,
];
export const manageImports: UserRole[] = [
  roleMappings.user,
  roleMappings.manager,
  roleMappings.preparer,
  roleMappings.billing,
];
export const assignForms: UserRole[] = [];

// Mailings
export const viewMailings: UserRole[] = [
  roleMappings.user,
  roleMappings.manager,
  roleMappings.preparer,
  roleMappings.billing,
  roleMappings.signer,
];

// Organization
export const manageOrganization: UserRole[] = [roleMappings.manager];

// Messages
export const manageMessages: UserRole[] = [
  roleMappings.manager,
  roleMappings.preparer,
  roleMappings.signer,
];

// Integrations
export const manageIntegrations: UserRole[] = [roleMappings.admin];

// Billing
export const manageBilling: UserRole[] = [roleMappings.billing];

// Auth Links
export const createAuthLinks: UserRole[] = [
  roleMappings.admin,
  roleMappings.preparer,
];

// Clients
export const manageClients: UserRole[] = [
  roleMappings.admin,
  roleMappings.manager,
];

// Client Locations
export const manageClientLocations: UserRole[] = [
  roleMappings.admin,
  roleMappings.manager,
];
