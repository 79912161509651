/* eslint-disable max-len */
import { BooleanGuard } from "@taxscribe/ui";
import React, { FC, PropsWithChildren, ReactNode, useMemo } from "react";
import { Col, Navbar, Row } from "react-bootstrap";
import styled from "styled-components";
import colors from "../../constants/colors";
import AppNavbarBrand from "./AppNavbarBrand";
import MyNotifications from "./MyNotifications";

interface NavbarProps {
  pageTitle?: ReactNode;
  additionalContent?: ReactNode;
  additionalContentColumnSize?: number;
}

const ChildrenAndNotifications = styled.div`
  display: flex;
  justify-content: end;
  gap: 1rem;
`;

const FullRow = styled(Row)`
  width: 100%;
`;

const StyledNavbar = styled(Navbar)`
  border-bottom: 2px solid ${colors.tsGray};
`;

const AppNavbar: FC<PropsWithChildren<NavbarProps>> = ({
  children,
  pageTitle,
  additionalContent = null,
  additionalContentColumnSize = 4,
}) => {
  const title = useMemo(() => {
    if (typeof pageTitle === "string") {
      return (
        <AppNavbarBrand>
          {pageTitle}
        </AppNavbarBrand>
      );
    }

    return pageTitle;
  }, [pageTitle]);

  return (
    <>
      <StyledNavbar
        id="header-nav"
        expand="lg"
        className="bg-white sticky-top p-3 justify-content-lg-between ls-1"
      >
        <FullRow>
          <Col>
            {title}
          </Col>
          <BooleanGuard condition={Boolean(additionalContent)}>
            <Col md={additionalContentColumnSize}>
              {additionalContent}
            </Col>
          </BooleanGuard>
          <Col md={5}>
            <ChildrenAndNotifications>
              {children}
              <MyNotifications />
            </ChildrenAndNotifications>
          </Col>
        </FullRow>
      </StyledNavbar>
    </>
  );
};

export default AppNavbar;
