import { useMemo } from "react";

import useOrganizationClients from "./useOrganizationClients";
import { OptionItem } from "../components/shared/FormikSelect";

const selectClientPrompt = {
  label: "Select a client",
  value: "",
};

const useOrganizationClientSelectOptions = () => {
  const { data } = useOrganizationClients();
  const clientOptions: OptionItem[] = useMemo(() => {
    if (!data) return [selectClientPrompt];
    const clientOpts = data?.organization?.clients?.map((client) => ({
      value: String(client.id),
      label: `${client.name} - ${client.contactName}`,
    })) || [];

    return [selectClientPrompt, ...clientOpts];
  }, [data]);

  return clientOptions;
};

export default useOrganizationClientSelectOptions;
