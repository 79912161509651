import React, { FC, useMemo } from "react";
import styled from "styled-components";
import { ClientLocation } from "@taxscribe/record-types";

import FormikSelect from "../FormikSelect";
import colors from "../../../constants/colors";
import printLocationOnOneLine from "../../../lib/printLocationOnOneLine";

export interface FormLocationModalProps {
  formUuid: string;
  stateAbbr: string;
  jurisdictionId: number;
}

const selectOption = { label: "Select", value: "" };

const NoLocationWrapper = styled.div`
  color: ${colors.tsRed};
`;

export interface ModalLocationSelectProps {
  stateAbbr: string;
  jurisdictionId: number;
  clientLocations: undefined | ClientLocation[];
}

const ModalLocationSelect: FC<ModalLocationSelectProps> = ({
  stateAbbr,
  jurisdictionId,
  clientLocations,
}) => {
  const locationOptions = useMemo(() => {
    if (!clientLocations?.length) return [];

    return [
      selectOption,
      ...clientLocations.map((location) => ({
        label: printLocationOnOneLine(location, stateAbbr),
        value: String(location.id),
      })),
    ];
  }, [clientLocations, stateAbbr]);

  if (!locationOptions.length) {
    return (
      <NoLocationWrapper>
        The selected client has no locations in this jurisdiction.
      </NoLocationWrapper>
    );
  }

  return (
    <FormikSelect
      label="Select a location"
      fieldName="locationId"
      options={locationOptions}
    />
  );
};

export default ModalLocationSelect;
