import { FC } from "react";
import FormikSelect, { FormikSelectProps } from "./FormikSelect";
// eslint-disable-next-line max-len
import useOrganizationClientSelectOptions from "../../hooks/useOrganizationClientSelectOptions";

const FormikClientSelect: FC<Omit<FormikSelectProps, "options">> = (props) => {
  const clientOptions = useOrganizationClientSelectOptions();

  return (
    <FormikSelect
      {...props}
      options={clientOptions}
    />
  );
};

export default FormikClientSelect;
