import { gql, useMutation } from "@apollo/client";

export const ATTACH_FORM_TO_LOCATION = gql`
  mutation AttachFormToLocation($formUuid: String!, $locationId: Int!) {
    attachFormToLocation(formUuid: $formUuid, locationId: $locationId) {
      ddbKey
      id
      clientLocationId
      clientLocation {
        id
        address
        addressTwo
        city
        zipCode
        clientId
        stateAbbr
        jurisdictionId
        createdAt
        updatedAt
      }
    }
  }
`;

export interface UseAttachFormToLocationArgs {
  formUuid: string;
  locationId: number;
}

const useAttachFormToLocation = () => {
  return useMutation<{
    formUuid: string;
    locationId: number;
  }>(ATTACH_FORM_TO_LOCATION);
};

export default useAttachFormToLocation;
