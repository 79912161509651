import React, { FC, PropsWithChildren, ReactNode } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";

interface StandardModalProps {
  header: ReactNode;
  footer?: ReactNode;
}

const StyledModalHeader = styled(Modal.Header)`
  border-bottom: 0px;
`;

const ConditionalFooter: FC<PropsWithChildren> = ({ children }) => {
  if (!children) return null;
  return <Modal.Footer>{children}</Modal.Footer>;
};

const StandardModal: FC<PropsWithChildren<StandardModalProps>> = ({
  header,
  footer,
  children,
}) => {
  return (
    <>
      <StyledModalHeader closeButton>
        <Modal.Title>{header}</Modal.Title>
      </StyledModalHeader>
      <Modal.Body>{children}</Modal.Body>
      <ConditionalFooter>{footer}</ConditionalFooter>
    </>
  );
};

export default StandardModal;
