import React, { FC, useCallback, useMemo } from "react";
import { Form } from "react-bootstrap";
import Select, { MultiValue, StylesConfig } from "react-select";
import { FieldHelperProps, FieldInputProps, useField } from "formik";
import colors from "../../constants/colors";

export interface OptionItem {
  value: string;
  label: string;
  isFixed?: boolean;
}

export interface FormikSelectProps {
  label?: string;
  isMulti?: boolean;
  fieldName: string;
  disabled?: boolean;
  placeholder?: string;
  options: OptionItem[];
}

interface FormikMultiSelectProps extends FormikSelectProps {
  field: FieldInputProps<any>;
  helpers: FieldHelperProps<any>;
}

const hideStaticOption: StylesConfig<OptionItem> = {
  multiValue: (base, state) => {
    if (state.data.isFixed) {
      return {
        ...base,
        backgroundColor: colors.tsBlueGrayDark,
      };
    }
    return base;
  },
  multiValueRemove: (base, state) => {
    if (state.data.isFixed) {
      return {
        ...base,
        display: "none",
      };
    }
    return base;
  },
};

const FormikMultiSelect: FC<FormikMultiSelectProps> = ({
  label,
  field,
  helpers,
  fieldName,
  disabled,
  placeholder,
  options,
}) => {
  const handleChange = useCallback(
    (data: MultiValue<{ value: string }>) => {
      helpers.setValue(data.map((d) => d.value));
    },
    [helpers],
  );

  const selections = useMemo(() => {
    return options
      .filter((option) => field.value?.includes(option.value))
      .sort(({ isFixed: optOneFixed }, { isFixed: optTwoFixed }) => {
        if (optOneFixed && !optTwoFixed) return -1;
        if (optTwoFixed && !optOneFixed) return 1;
        return 0;
      });
  }, [options, field.value]);

  return (
    <Form.Group>
      {label ? <Form.Label htmlFor={fieldName}>{label}</Form.Label> : null}
      <Select
        isMulti
        options={options}
        value={selections}
        onChange={handleChange}
        styles={hideStaticOption}
      />
    </Form.Group>
  );
};

const FormikSelect: FC<FormikSelectProps> = (props) => {
  const { label, fieldName, disabled, placeholder, options, isMulti } = props;
  const [field, meta, helpers] = useField(fieldName);
  if (isMulti) {
    return (
      <FormikMultiSelect
        field={field}
        helpers={helpers}
        {...props}
      />
    );
  }

  return (
    <Form.Group>
      {label ? <Form.Label htmlFor={fieldName}>{label}</Form.Label> : null}
      <Form.Select
        {...field}
        id={fieldName}
        disabled={disabled}
        placeholder={placeholder}
        isInvalid={Boolean(meta.touched && meta.error)}
      >
        {options.map((option) => (
          <option
            key={option.value}
            value={option.value}
          >
            {option.label}
          </option>
        ))}
      </Form.Select>
      <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
    </Form.Group>
  );
};

export default FormikSelect;
