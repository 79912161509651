import { useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
import { gql, useLazyQuery } from "@apollo/client";
import { useUserDoc } from "@reasongcp/react-fire-sub";

export interface GetOrganizationClientsResults {
  organization: {
    clients: {
      id: number;
      organizationId: string;
      email: string;
      name: string;
      contactName: string;
      phoneNumber: string;
      address: string;
      addressTwo: string;
      city: string;
      state: string;
      website?: string | null;
      locationsCount?: number;
      zipCode: string;
      createdAt: string;
      updatedAt: string;
    }[];
  };
}

export const GET_ORGANIZATION_CLIENTS = gql`
  query GetOrganizationClients($uuid: String!, $textSearch: String) {
    organization(uuid: $uuid) {
      uuid
      clients(textSearch: $textSearch) {
        id
        organizationId
        email
        name
        contactName
        phoneNumber
        address
        addressTwo
        city
        state
        zipCode
        website
        locationsCount
        createdAt
        updatedAt
      }
    }
  }
`;

export interface UseOrganizationClientsArgs {
  textSearch?: string;
}

const useOrganizationClients = (args?: UseOrganizationClientsArgs) => {
  const { textSearch } = args || {};
  const userDoc = useUserDoc();
  const orgUuid = userDoc?.currentOrganization as string | undefined;
  const [fetchClients, results] = useLazyQuery<
    GetOrganizationClientsResults,
    { uuid: string, textSearch?: string }
  >(GET_ORGANIZATION_CLIENTS, {
    fetchPolicy: "cache-and-network",
  });

  const debouncedFetch = useDebouncedCallback(fetchClients, 500);

  useEffect(() => {
    if (orgUuid) {
      debouncedFetch({ variables: { uuid: orgUuid, textSearch } });
    }
  }, [orgUuid, debouncedFetch, textSearch]);

  return results;
};

export default useOrganizationClients;
