import { ClientLocation } from "@taxscribe/record-types";

const printLocationOnOneLine = (
  location: ClientLocation,
  stateAbbr: string,
) => {
  const fullAddress = [location.address, location.addressTwo]
    .filter(Boolean)
    .join(" ");

  // eslint-disable-next-line max-len
  return `${fullAddress}, ${location.city}, ${stateAbbr} ${location.zipCode}`;
};

export default printLocationOnOneLine;
