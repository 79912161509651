import { Navbar } from "react-bootstrap";
import styled from "styled-components";

const AppNavbarBrand = styled(Navbar.Brand)`
  font-size: 1.75rem;
  letter-spacing: 0.1rem;
  text-transform: capitalize;
  font-weight: 700;
`;

export default AppNavbarBrand;
